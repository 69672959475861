globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"rF88uUxqWG-0fXUoiKuMA"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import {
  init,
  replayIntegration,
  browserTracingIntegration,
} from "@sentry/nextjs";

const SENTRY_ENV = process.env.SENTRY_ENV || process.env.NEXT_PUBLIC_SENTRY_ENV;

if (
  (process.env.SENTRY_ENABLED && process.env.SENTRY_ENABLED === "enabled") ||
  (process.env.NEXT_PUBLIC_SENTRY_ENABLED &&
    process.env.NEXT_PUBLIC_SENTRY_ENABLED === "enabled")
)
  init({
    environment: SENTRY_ENV,
    release: "token-platform-frontend@2024.10.6",

    dsn: "https://78acc298914142a5a24c164c242657e9@o4505209998278656.ingest.us.sentry.io/4505210000375808",

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    replaysOnErrorSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 1.0,

    tracePropagationTargets: [
      process.env.NEXT_PUBLIC_BACKEND_API_URL,
      process.env.NEXT_PUBLIC_CLIENTSIDE_API_URL,
    ],

    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    integrations: [
      replayIntegration({
        // Additional Replay configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [`${process.env.NEXT_PUBLIC_URL}/api/*`],
        networkRequestHeaders: ["X-Custom-Header"],
        networkResponseHeaders: ["X-Custom-Header"],
      }),
      browserTracingIntegration(),
    ],

    beforeSendTransaction(event) {
      // Modify or drop the event here
      if (
        event.transaction === "/api/healthcheck" ||
        event.transaction === "middleware"
      ) {
        // Don't send the event to Sentry
        return null;
      }
      return event;
    },

    ignoreTransactions: ["middleware"],
  });
